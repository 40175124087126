import { authAPI, instanse } from "../API/authAPI"

export const refreshToken = (url, method, params = null, data = null, options = null) => {
    return instanse.get(`auth/user?token=${localStorage.getItem('token')}`)
        .then(() => {
            if(method === "get"){
                return instanse.get(url, {params}, options)
                .then(res => res)
                .catch(e => e)
            }
            if(method === "post"){
                return instanse.post(url, data, {params}, options)
                .then(res => res)
                .catch(e => e)
            }
            if(method === "put"){
                return instanse.put(url, data, {params}, options)
                .then(res => res)
                .catch(e => e)
            }
        })
        .catch((e) => {
            if(localStorage.getItem("token").length > 0 && e.response.status === 401){
                return authAPI.refresh()
                .then(res => {
                    localStorage.setItem('token', res.data.access_token)
                    if(method === "get"){
                        return instanse.get(url, {params: {...params, token: localStorage.getItem("token")}}, options)
                        .then(res => res)
                        .catch(e =>e)
                    }
                    if(method === "post"){
                        return instanse.post(url, data, {params: {...params, token: localStorage.getItem("token")}}, options)
                        .then(res => res)
                        .catch(e => e)
                    }
                    if(method === "put"){
                        return instanse.put(url, data, {params: {...params, token: localStorage.getItem("token")}}, options)
                        .then(res => res)
                        .catch(e => e)
                    }
                })
                .catch(e => e)
            }
        })
}


// export const refreshToken = (error, callback = null) => {
//     if(localStorage.getItem("token").length > 0 && error.response.status === 401){
//         authAPI.refresh()
//         .then(res => localStorage.setItem('token', res.data.access_token))
//         .then(() => callback())
//         .catch(() => callback())
//     }
// }