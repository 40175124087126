import s from "./Telegram.module.css";
import { Input, Form, message } from "antd";
import close from "../../images/close.png";
import { authAPI } from "../../API/authAPI";
import { useDispatch } from "react-redux";
import { getMeThunk } from "../../Redux/authReduser";



const Telegram = (props) => {
  const { setTg } = props;
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  const error = (content) => {
    messageApi.open({
      type: "error",
      content: `${content}`,
    });
  };

  const onFinish = async (values) => {
    const response = await authAPI.tgLogin(values.telegram_code);
      if (response === 200) {
        dispatch(getMeThunk());
        props.setTg(false);
      } else {
        error("Ошибка входа");
      }
  };

  return (
    <div>
      {contextHolder}
      <div onClick={() => setTg(false)} className={s.modal_back}></div>
      <div className={s.window}>
        <img
          onClick={() => setTg(false)}
          src={close}
          alt="close"
          className={s.close_img}
        />
        <div className={s.reg_title}>Войдите с помощь Telegram</div>
        <span className={s.add_title}>Войдите с помощь Telegram </span>
        <span className={s.add_title}> используя код из Telegram бота</span>
        <div className={s.black_title}>Введите код из Telegram бота</div>
        <a className={s.link} href="https://t.me/GreedyMarketBot" target="blank">@GreedyMarketBot</a>
        <Form onFinish={onFinish}>
          <Form.Item
            name="telegram_code"
            rules={[
              {
                required: true,
                message: "Please input your telegram code!",
              },
            ]}
          >
            <Input className={s.password_input} type="" placeholder="" />
          </Form.Item>
          <Form.Item>
            <button type="submit" className={s.login_form_button}>
              Подтвердить
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Telegram;
