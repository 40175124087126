import { useNavigate } from "react-router-dom";
import s from "../Policy/Policy.module.css";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className={s.container}>
      <div className={s.head}>О нас</div>
      <div className={s.main}>
        <a className={s.link} onClick={() => navigate("/")}>
          GreedyMarket.org
        </a>
        - это магазин электронных товаров и аккаунтов. Аккаунты, ключи,
        промокоды и многое другое. Мы продаем только те аккаунты, которые
        зарегистрировали сами. Не занимаемся взломом и продажей чужих аккаунтов.
      </div>
    </div>
  );
};

export default AboutUs;
