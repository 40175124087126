import axios from "axios"
import { refreshToken } from "../utils/refreshToken"

export const instanse = axios.create({
    baseURL: "https://greedymarket.org/api/"
})

export const authAPI = {
    register(email, username, password){
        return instanse.post("auth/register", {email, username, password})
        .then(res => res)
        .catch(e => e)
    },
    tgRegister(telegram_username){
        return instanse.post("auth/register", {telegram_username})
        .then(res => res)
        .catch(e => e)
    },
    login(email, password){
        return instanse.post("auth/token", {email, password})
        .then(res => {
            localStorage.setItem('token', res.data.access_token)
            return res.status
        })
        .catch(e => e)
    },
    tgLogin(telegram_code){
        return instanse.post("auth/token", {telegram_code})
        .then(res => {
            localStorage.setItem('token', res.data.access_token)
            return res.status
        })
        .catch(e => e)
    },
    confirmEmail(email_code){
        return instanse.get(`auth/verify_email?email_code=${email_code}`)
        .then(res => res)
        .catch(e => e)
    },
    telegram(telegram_username){
        return refreshToken("auth/add_telegram", "get", {token: localStorage.getItem("token"), telegram_username})
        .then(res => res)
        .catch(e => e)
    },
    resetPassword(email){
        return instanse.get(`auth/reset?email=${email}`)
        .then(res => res)
        .catch(e => e)
    },
    verifyResetPassword(reset_code, new_password){
        return instanse.get(`auth/verify_reset?reset_code=${reset_code}&new_password=${new_password}`)
        .then(res => res)
        .catch(e => e)
    },
    buyProduct(product_id, amount, promocode){
        if(promocode){
            return refreshToken("auth/buy", "post", {token: localStorage.getItem("token"), product_id, amount, promocode: "%23"+promocode})
            .then(res => res)
            .catch(e => e)
        }
        else{
            return refreshToken("auth/buy", "post", {token: localStorage.getItem("token"), product_id, amount}) 
            .then(res => res)
            .catch(e => e)
        }
    },
    check(){
        return refreshToken("auth/user", "get", {token: localStorage.getItem("token")})
        .then(res => res)
        .catch(e => e)
    },
    refresh(){
        return instanse.post(`auth/refresh_token?token=${localStorage.getItem('token')}`)
        .then(res => res)
        .catch(e => e)
    }
}
