import CatalogItem from "../CatalogItem/CatalogItem";
import s from "./CategorySection.module.css"
import { useEffect, useState } from "react";
import { productsAPI } from "../../../API/productsAPI";

const CategorySection = (props) => {

  const [more, setMore] = useState(1)
  const [products, setProducts] = useState()

  useEffect(() => {
    setProducts(null)
    productsAPI.getProductsByCategory(props.category.id, props.category.title, props.category.color)
    .then(res => setProducts(res.data))
  },[props.category])
  const [hover, setHover] = useState(false)
  return (
    <div className={s.tiktok_section}>
      <div style={{backgroundColor: props.category.color}} className={s.tik_tok}>
        <div className={s.tik_tok_title}>{props.category.title}</div>
        <img className={s.section_img} src={"data:image/png;base64,"+props.img} alt="img" />
      </div>
      {props.searchedProducts?.length>0?props.searchedProducts?.slice(0, more*4)?.map((prod) => <CatalogItem key={prod.id} setError={props.setError} setName={props.setName} setPrice={props.setPrice} setAmount={props.setAmount} setDownloadUrl={props.setDownloadUrl} setSucPay={props.setSucPay} product={prod} />)
      :
      products?.slice(0, more*4)?.map((prod) => <CatalogItem key={prod.id} setError={props.setError} setName={props.setName} setPrice={props.setPrice} setAmount={props.setAmount} setDownloadUrl={props.setDownloadUrl} setSucPay={props.setSucPay} img={props.img} product={prod} />)}
      <button style={hover?{backgroundColor: props.category.color, border: `1px dashed ${props.category.color}`}:{border: `1px dashed ${props.category.color}`}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={s.show_more_tt} onClick={() => setMore(more+1)} >Показать больше товаров</button>
    </div>
  );
};

export default CategorySection
