import { Button } from "antd";
import s from "./AdminProduct.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteProductThunk,
  editProductThunk,
} from "../../../../Redux/productsReduser";
import edit from "../../../../images/edit.png";
import trash from "./../../../../images/trash-can.png"

const AdminProduct = (props) => {
  const dispatch = useDispatch();

  const onSave = () => {
    if(isInChanging === true){
      setIsInChanging(false);
      dispatch(editProductThunk(props.prod, {id: props.prod.id, title, description, price, categories: props.prod.categories}));
    }
    else setIsInChanging(true)
  };

  const onDelete = () => {
    dispatch(deleteProductThunk(props.prod));
  };

  const onTitleChange = (event) => {
    if (isInChanging) {
      setTitle(event.target.value);
    }
  };
  const onDescriptionChange = (event) => {
    if (isInChanging) {
      setDescription(event.target.value);
    }
  };
  const onPriceChange = (event) => {
    if (isInChanging) {
      setPrice(event.target.value);
    }
  };

  const [isInChanging, setIsInChanging] = useState(false);
  const [title, setTitle] = useState(props.prod.title);
  const [description, setDescription] = useState(props.prod.description);
  const [price, setPrice] = useState(props.prod.price);

  return (
    <>
      <div className={s.container}>
        <Button
          onClick={() => setIsInChanging(true)}
          type="dashed"
          style={{ marginRight: "50px" }}
        >
          Редактировать
        </Button>
        <div className={s.ctg_item}>
          <div className={s.item}>
            <div className={s.attribute}>title:</div>
            <textarea
              className={isInChanging ? s.unlocked_field : s.locked_field}
              onChange={onTitleChange}
            />
          </div>
          <div className={s.item}>
            <div className={s.attribute}>price:</div>
            <input
              disabled={isInChanging ? false : true}
              className={isInChanging ? s.unlocked_field : s.locked_field}
              onChange={onPriceChange}
            />
          </div>
          <div className={s.item}>
            <div className={s.attribute}>description:</div>
            <textarea
              className={isInChanging ? s.unlocked_field : s.locked_field}
              onChange={onDescriptionChange}
            />
          </div>
        </div>
        <Button onClick={onSave} type="dashed">
          Сохранить
        </Button>
        <Button onClick={onDelete} type="dashed">
          Удалить
        </Button>
      </div>
      <div>
        <div className={s.ctg_item}>
          {isInChanging ? (
            <textarea
              className={s.unlocked_field}
              value={title}
              onChange={onTitleChange}
            />
          ) : (
            <div className={s.product_title}>{props.prod.title}</div>
          )}
          {isInChanging?<input
              value={price}
              className={s.unlocked_field}
              onChange={onPriceChange}
            />:<div className={s.product_price}>
            {props.prod.price}<span className={s.price_item}>₽</span>
          </div>}
          {isInChanging?<textarea
              className={s.unlocked_field}
              value={description}
              onChange={onDescriptionChange}
            />:<div className={s.footer_btn}>{props.prod.description}</div>}
           
          <img
            onClick={onSave}
            className={s.edit}
            src={edit}
            alt="edit"
          />
          <img
            onClick={onDelete}
            className={s.delete}
            src={trash}
            alt="edit"
          />
          
        </div>
      </div>
    </>
  );
};

export default AdminProduct;
