import { useDispatch, useSelector } from "react-redux";
import AdminCategorie from "./AdminCategorie/AdminCategorie";
import { Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import s from "./AdminCategories.module.css";
import { addCategory } from "../../../Redux/productsReduser";
import { useEffect, useState } from "react";
import close from "../../../images/close.png";

const AdminCategories = () => {
  const [file, setFile] = useState();
  const onFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  const [category, setCategory] = useState(false);

  const isAdmin = useSelector((state) => state.Auth.isAdmin);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAdmin) navigate("/");
  }, []);

  const dispatch = useDispatch();

  const categories = useSelector((state) => state.Products.categories);

  const onCategorieAdd = (values) => {
    const color = values.color.substring(1)
    const formData = new FormData();
    formData.append("file", file);
    dispatch(addCategory(values.title, color, formData));
    setCategory(false)
  };

  return (
    <>
      <div className={s.category_add}>
        <h1 style={{ marginBottom: 30 }}>Список всех категорий:</h1>
        <button onClick={() => setCategory(true)} className={s.footer_btn}>
          <div className={s.plus_title}>Добавить новую категорию</div>
          <span className={s.plus}>+</span>
        </button>
        <div className={s.ctgs}>
          {categories.map((cat) => (
            <AdminCategorie key={cat.id} categorie={cat} />
          ))}
        </div>
      </div>
      {category && (
        <div className={s.window}>
          <img
            onClick={() => setCategory(false)}
            src={close}
            alt="close"
            className={s.close_img}
          />
          <div className={s.reg_title}>Добавьте новую категорию</div>
          <span className={s.add_title}>Введи название новой категории </span>
          <div className={s.black_title}>Название</div>
          <Form onFinish={(values) => onCategorieAdd(values)}>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input className={s.user_input} placeholder="" />
            </Form.Item>
            <div className={s.black_title}>Цвет категории</div>
            <Form.Item
              name="color"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <input type="color" className={s.user_input}></input>
            </Form.Item>
            <Form.Item
              name="file"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <input onChange={onFileSelect} type="file"></input>
            </Form.Item>
            <Form.Item>
              <button type="submit" className={s.login_form_button}>
                Добавить
              </button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

export default AdminCategories;
