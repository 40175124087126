import { refreshToken } from "../utils/refreshToken"
import { instanse } from "./authAPI"



export const productsAPI = {
    getAllProducts(offset){
        return instanse.get(`product/?offset=${offset}`)
        .then(res => res)
    },
    getOneProduct(id){
        return instanse.get(`product/product/${id}`)
        .then(res => res)
    },
    getCategories(){
        return instanse.get("product/categories")
        .then(res => res)
    },
    addCategorie(title, color, file){
        return refreshToken("product/category", "post", {token: localStorage.getItem("token"), title, color: "%23"+color}, {file})
        .then(res => res)
        .catch(e => e)
    },
    getProductsByCategory(id, title, color){
        return instanse.post("product/by_category", {id, title, color})
        .then(res => res)
        .catch(e => e)
    },
    addProduct(title, description, price, categories, link){
        return refreshToken("product", "post", {token: localStorage.getItem("token")}, {id: 0, title, description, price, categories, link})
        .then(res => res)
        .catch(e => e)
    },
    editCategory(id, title, color, new_title){
        return refreshToken("product/category", "put", {token: localStorage.getItem("token"), new_title}, {id, title, color})
        .then(res => res)
        .catch(e => e)
    },
    editProduct(product, new_product){
        return refreshToken("product/product", "put", {token: localStorage.getItem("token")}, {product, new_product})
        .then(res => res)
        .catch(e => e)
    },
    deleteProduct({id}){
        return refreshToken(`product/${id}`, "post", {token: localStorage.getItem("token")})
        .then(res => res)
        .catch(e => e)
    },
    getCategoryImg(id){
        return instanse.post(`product/category/image?id=${id}`,{}, {responseType: "arraybuffer"})
        .then(res => res)
        .catch(e => e)
    },
    addOrderItems(product_id, accounts){
        return refreshToken("product/order_item", "post", {token: localStorage.getItem("token"), product_id}, {accounts})
        .then(res => res)
        .catch(e => e)
    },
    getProductsCount(product_id){
        return instanse.get(`product/count_orders?product_id=${product_id}`)
        .then(res => res)
        .catch(e => e)
    },
    searchProduct(query){
        return instanse.get(`product/search?query=${query}`)
        .then(res => res)
        .catch(e => e)
    },
    deleteCategory(id){
        return refreshToken(`product/category/${id}`, "post", {token: localStorage.getItem("token")})
        .then(res => res)
        .catch(e => e)
    },
    
}
