import s from "./PasswordRestore.module.css";
import { Input, Form, message } from "antd";
import close from "../../images/close.png";
import { authAPI } from "../../API/authAPI";

const PasswordRestore = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const error = () => {
    messageApi.open({
      type: "error",
      content: "Ошибка",
    });
  };

  const { setPass, setPassSecond } = props;
  const onCodeSend = async (values) => {
    const res = await authAPI.resetPassword(values.email)
    if(res.status === 200){
        setPass(false);
        setPassSecond(true);
    }
    else error()
  };
  return (
    <div>
      {contextHolder}
      <div onClick={() => setPass(false)} className={s.modal_back}></div>
      <div className={s.window}>
        <img
          onClick={() => setPass(false)}
          src={close}
          alt="close"
          className={s.close_img}
        />
        <div className={s.reg_title}>Восстановление пароля</div>
        <span className={s.add_title}>Восстановите ваш аккаунт </span>
        <span className={s.add_title}> используя свою электронную почту </span>
        <div className={s.black_title}>Электронная почта</div>
        
        <Form onFinish={onCodeSend}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              className={s.password_input}
              type="email"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item>
            <button type="submit" className={s.login_form_button}>
              Отправить код
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PasswordRestore;
