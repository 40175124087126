import { useSelector } from "react-redux";
import AdminAddAccCategory from "./AdminAddAccCategory/AdminAddAccCategory";

const AdminAddAccounts = () => {
  
  const categories = useSelector((state) => state.Products.categories);

  return (
    <>
      {categories.map((cat) => (
        <AdminAddAccCategory key={cat.id} cat={cat} />
      ))}
    </>
  );
};

export default AdminAddAccounts;
