import Login from "../Login/Login";
import Register from "../Register/Register";
import { useState } from "react";
import s from "./Header.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { LogIn } from "../../Redux/authReduser";
import EmailConfirmation from "../Register/EmailConfirmation/EmailConfirmation";
import PasswordRestore from "../PasswordRestore/PasswordRestore";
import PasswordRestoreSecond from "../PasswordRestoreSecond/PasswordRestoreSecond";
import Telegram from "../Telegram/Telegram";
import header_burger from "../../images/header_burger.png"
import devilprofile from "../../images/devilprof.png"
import BalanceTransferForm from "../BalanceTransfer/BalanceTransferForm/BalanceTransferForm";
import Profile from "../Profile/Profile";
import Fund from "../../Fund/Fund";
import Purchase from "../Purchase/Purchase";
import SucPay from "../SucPay/SucPay";
import LOGO from "../../images/LOGO54.png"
import TelegramBinding from "../Telegram/TelegramBinding";




const Header = (props) => {

  const navigate = useNavigate();

  const balance = useSelector((state) => state.Auth.balance)
  const isAuth = useSelector((state) => state.Auth.isAuth)
  const isAdmin = useSelector((state) => state.Auth.isAdmin)
  
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const [emailConfirmation, setEmailConfirmation] = useState(false);
  const [pass, setPass] = useState(false);
  const [tg, setTg] = useState(false);
  const [burger, setBurger] = useState(false);
  const [transfer, setTransfer] = useState(false)
  const [profile, setProfile] = useState(false)
  const [fund, setFund] = useState(false)
  const [purchase, setPurchase] = useState(false)
  
  const [passSecond, setPassSecond] = useState(false);
  const [tgBinding, setTgBinding] = useState(false)
  const [tgBindingProfile, setTgBindingProfile] = useState(false)
  const [active,setActive] = useState(false)

  

  const modalTransferChanger = (bool) => {
    setTransfer(bool)
  }

  const modalTgBindingChanger = (bool) => {
    setTgBinding(bool)
  }
  const modalTgBindingChangerProfile = (bool) => {
    setTgBinding(bool)
    setTgBindingProfile(bool)
  }

  const modalProfileChanger = (bool) => {
    setProfile(bool)
  }

  const modalFundChanger = (bool) => {
    setFund(bool)
  }

  const modalPurchaseChanger = (bool) => {
    setPurchase(bool)
  }

  const handleOpenLogin = () => {
    setLogin(true);
  };
  const handleOpenRegister = () => {
    setRegister(true);
  };

  const modalSecondPassChanger = (bool) => {
    setPassSecond(bool);
  };
  const modalBurgerChanger = (bool) => {
   setActive(bool)
    setBurger(bool);
  };

  const modalPassChanger = (bool) => {
    setPass(bool);
  };


  const modalLoginChanger = (bool) => {
    setLogin(bool);
  };
  const modalRegisterChanger = (bool) => {
    setRegister(bool);
  };
  const modalEmailConfirmationChanger = (bool) => {
    setEmailConfirmation(bool);
  };
  const modalTgChanger = (bool) => {
    setTg(bool);
  };

  

  return (
    <>
      {login && <Login setTg={modalTgChanger} setPassSecond={modalSecondPassChanger} setPass={modalPassChanger} setAuth={LogIn} setLogin={modalLoginChanger} setEmailConfirmation={modalEmailConfirmationChanger} />}
      {pass && <PasswordRestore setPassSecond={modalSecondPassChanger} setPass={modalPassChanger} />}
      {passSecond && <PasswordRestoreSecond setPassSecond={modalSecondPassChanger} />}
      {tg && <Telegram setTg={modalTgChanger} />}
      {tgBinding && <TelegramBinding isProfile={tgBindingProfile} setLogin={modalLoginChanger} setTg={modalTgBindingChanger} />}

      {register && (
        <Register
          setTgBinding={modalTgBindingChanger}
          setTg={modalTgChanger}
          setLogin={modalLoginChanger}
          setRegister={modalRegisterChanger}
          setEmailConfirmation={modalEmailConfirmationChanger}
        />
      )}
      {emailConfirmation && (
        <EmailConfirmation
          setLogin={modalLoginChanger}
          setRegister={modalRegisterChanger}
          setEmailConfirmation={modalEmailConfirmationChanger}
        />
      )}
      {transfer && <BalanceTransferForm setTransfer={modalTransferChanger} />}
      {profile && <Profile setTg={modalTgBindingChangerProfile} transfer={transfer} setTransfer={modalTransferChanger} profile={profile} setProfile={modalProfileChanger} />}
      {fund?<Fund setErrorFund={props.setFund} setFund={modalFundChanger} />:props.fund?<Fund setErrorFund={props.setFund} setFund={modalFundChanger} />:null}
      {purchase && <Purchase setSucPay={props.setSucPay} setPurchase={modalPurchaseChanger} />}
      {props.sucPay && <SucPay name={props.name} price={props.price} amount={props.amount} downloadUrl={props.downloadUrl} setSucPay={props.setSucPay} />}

      <div className={s.promo}>Скидка 5% на любые товары по промокоду #GREEDY</div>
    


      <div className={s.header}>
        <div
          onClick={() => navigate("/")}
          className={s.logo}>
          <img src={LOGO} alt="" />
        </div>


            <a className={s.nav__link_desk} href="#">Главная</a>
            <a className={s.nav__link_desk} target='blank' href='https://t.me/GreedyMarketHELPbot'>Поддержка</a>
            <a className={s.nav__link_desk} onClick={() => navigate("/faq")}>FAQ</a>

       
        <div className={s.mobile_profile}>
        {isAuth ? <div>
          <div className={s.header_avatar_and_button}>
            <div onClick={() => modalProfileChanger(true)} className={s.balance_name}>
              <div className={s.user_balancemain}>Баланс</div>
              <span className={s.user_balance} >{balance.toFixed(2)}₽</span>
              
            </div>
            <img onClick={() => modalProfileChanger(true)} className={s.devilprofile} src={devilprofile} alt="devilprofile" />
          </div>
        </div>:null}
        <img onClick={burger ? () => modalBurgerChanger(false) : () => modalBurgerChanger(true)} className={active ? s.header_burger_active : s.header_burger } src={header_burger} alt="header_burger" />
          </div>
        <div className={s.column3}>
          <div className={s.registration}>
            {isAuth ? (
              <div>
                <div className={s.header_avatar_and_button}>
                  <div className={s.balance_name}>
                    <div className={s.user_balancemain}>Баланс</div>
                    <span className={s.user_balance} >{balance.toFixed(2)}₽</span>
                    <div onClick={() => setFund(true)} className={s.poplnit}>Пополнить</div>
                  </div>
                  <img style={{cursor: 'pointer'}} onClick={() => modalProfileChanger(true)} src={devilprofile} alt="devilprofile" />
                </div>
              </div>
            ) : (
              <div className={s.knopki}>
                <button onClick={handleOpenLogin} className={s.sign_in}>
                  Войти
                </button>
                <button onClick={handleOpenRegister} className={s.sign_up}>
                  Зарегистрироваться
                </button>
              </div>
            )}

            {isAdmin && isAuth ? (
              <button onClick={() => navigate("/admin")} className={s.admin}>
                Админ
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {burger &&
        <>
        {isAuth ? (
          <div className={s.column54}>
          <div className={s.nav__links}>
            <a className={s.nav__link} href="#">Главная</a>
            <a className={s.nav__link} target='blank' href='https://t.me/GreedyMarketHELPbot'>Поддержка</a>
            <a className={s.nav__link} onClick={() => navigate("/faq")}>FAQ</a>
          </div>
          <div className={s.knopki}>
            <button onClick={() => modalFundChanger(true)} className={s.sign_up}>
              Пополнить баланс
            </button>
            <button onClick={() => modalProfileChanger(true)} className={s.poplnit1}>
            Мой профиль 
            </button>
          </div>
        </div>
        ):(
          <div className={s.column54}>
            <div className={s.nav__links}>
              <a className={s.nav__link} href="#">Главная</a>
              <a className={s.nav__link} target='blank' href='https://t.me/GreedyMarketHELPbot'>Поддержка</a>
              <a className={s.nav__link} onClick={() => navigate("/faq")}>FAQ</a>
            </div>
            <div className={s.knopki}>
              <button onClick={handleOpenRegister} className={s.sign_up}>
                Зарегистрироваться
              </button>
              <button onClick={handleOpenLogin} className={s.sign_in}>
                Войти
              </button>
            </div>
          </div>
        )
}
        </>}
    </>
  );
};

export default Header;
