import { productsAPI } from "../API/productsAPI";
import { Buffer } from 'buffer';

const initialState = {
  products: [],
  categories: [],
  activeCategory: null,
  catalogImgs: [],
  selectedImg: null,
  page: 1,
  month: []
};

export const productsReduser = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_PRODUCT":
      return {
        ...state,
        products: state.products.map((prod) =>
          prod.id === action.id
            ? {
                ...prod,
                name: action.name,
                description: action.description,
                img: action.img,
              }
            : prod
        ),
      };
    case "CHANGE_CATEGORIE":
      return {
        ...state,
        categories: state.categories.map((cat) =>
          cat.id === action.id
            ? {
                ...cat,
                value: action.value
              }
            : cat
        ),
      };
    case "SET_PRODUCTS":
      return {
        ...state,
        products: [...action.products]
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: [...action.categories]
      };
    case "SET_ACTIVE_CATEGORY":
      return {
        ...state,
        activeCategory: action.id?{...action}:null
      };
    case "SET_CATALOG_CATEGORY_IMGS":
      return {
        ...state,
        catalogImgs: [...action.imgs]
      };
    case "SET_CATALOG_CATEGORY_IMG":
      return {
        ...state,
        selectedImg: action.img
      };
    case "SET_PAGE":
      return {
        ...state,
        page: action.page
      };
    default:
      return state;
  }
};

export const ChangeProduct = (id, name, description, img) => {
  return {
    type: "CHANGE_PRODUCT",
    id,
    name,
    description,
    img,
  };
};
export const ChangeCategorie = (id, value) => {
  return {
    type: "CHANGE_CATEGORIE",
    id,
    value
  };
};

export const SetProducts = (products) => {
  return {
    type: "SET_PRODUCTS",
    products
  };
};

export const SetCategories = (categories) => {
  return {
    type: "SET_CATEGORIES",
    categories
  };
};

export const SetActiveCategory = (id, title, color) => {
  return {
    type: "SET_ACTIVE_CATEGORY",
    id,
    title,
    color
  };
};

export const SetCatalogCategoryImgs = (imgs) => {
  return {
    type: "SET_CATALOG_CATEGORY_IMGS",
    imgs
  };
};

export const SetCatalogCategoryImg = (img) => {
  return {
    type: "SET_CATALOG_CATEGORY_IMG",
    img
  };
};

export const SetPage = (page) => {
  return {
    type: "SET_PAGE",
    page
  };
};


export const getProducts = (offset) => async (dispatch) => {
  const response = await productsAPI.getAllProducts(offset)
  dispatch(SetProducts(response.data))
}

export const getCategories = () => async (dispatch) => {
  const response = await productsAPI.getCategories()
  dispatch(SetCategories(response.data))
}

export const addCategory = (title, color, file) => async (dispatch) => {
  const res = await productsAPI.addCategorie(title, color, file)
  if(res.status === 200){
    const response = await productsAPI.getCategories()
    dispatch(SetCategories(response.data))
  }
  else(alert("error"))
}

export const setActiveCategoryThunk = (id, title, color) => async (dispatch) => {
  const response = await productsAPI.getProductsByCategory(id, title, color)
  dispatch(SetProducts(response.data))
}

export const addProductThunk = (title, description, price, categories, link) => async (dispatch) => {
  const res = await productsAPI.addProduct(title, description, price, categories, link)
  if(res.status === 200){
    const response = await productsAPI.getAllProducts(0)
    dispatch(SetProducts(response.data))
  }
  else(alert("error"))
}

export const editCategoryThunk = (id, title, color, new_title) => async (dispatch) => {
  const res = await productsAPI.editCategory(id, title, color, new_title)
  if(res.status === 200){
    const response = await productsAPI.getCategories()
    dispatch(SetCategories(response.data))
  }
  else(alert("error"))
}

export const deleteCategoryThunk = (id) => async (dispatch) => {
  const res = await productsAPI.deleteCategory(id)
  if(res.status === 200){
    const response = await productsAPI.getCategories()
    dispatch(SetCategories(response.data))
  }
  else(alert("error"))
}

export const editProductThunk = (product, new_product) => async (dispatch) => {
  const res = await productsAPI.editProduct(product, new_product)
  if(res.status === 200){
    const response = await productsAPI.getAllProducts(0)
    dispatch(SetProducts(response.data))
  }
  else(alert("error"))
}

export const deleteProductThunk = (product) => async (dispatch) => {
  const res = await productsAPI.deleteProduct(product)
  if(res.status === 200) {
    const response = await productsAPI.getAllProducts(0)
    dispatch(SetProducts(response.data))
  }
  else(alert("error"))
}

export const getCategoriesImgsThunk = (ids) => async (dispatch) => {
  const res1 = await productsAPI.getCategoryImg(ids[0])
  const res2 = await productsAPI.getCategoryImg(ids[1])
  const res3 = await productsAPI.getCategoryImg(ids[2])
  dispatch(SetCatalogCategoryImgs([Buffer.from(res1.data?res1.data:"", 'binary').toString('base64'), Buffer.from(res2.data?res2.data:"", 'binary').toString('base64'), Buffer.from(res3.data?res3.data:"", 'binary').toString('base64')]))
}

export const getCategoryImgThunk = (id) => async (dispatch) => {
  const res = await productsAPI.getCategoryImg(id)
  dispatch(SetCatalogCategoryImg(Buffer.from(res.data?res.data:"", 'binary').toString('base64')))
}

export const setActiveCatThunk = (id, title, color) => async (dispatch) => {
  await dispatch(getCategoryImgThunk(id))
  dispatch(SetActiveCategory(id, title, color));
}

