import { legacy_createStore as createStore, combineReducers, compose, applyMiddleware } from "redux"
import thunkMiddleware from "redux-thunk"
import {composeWithDevTools} from 'redux-devtools-extension';
import { authReduser } from "./authReduser";
import { productsReduser } from "./productsReduser";
import { usersReduser } from "./usersReduser";

let redusers = combineReducers({
    Auth: authReduser,
    Products: productsReduser,
    Users: usersReduser
})

export let store = createStore(redusers, composeWithDevTools(compose(applyMiddleware(thunkMiddleware))))