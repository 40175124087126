import { useState } from "react";
import s from "./AdminUser.module.css";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { changeUserAdminStatusThunk, changeUserBalanceThunk } from "../../../../Redux/usersReduser";
import edit from "../../../../images/edit.png"

const AdminUser = (props) => {
  const dispatch = useDispatch();

  const onSave = () => {
    if(isInChanging === true){
      setIsInChanging(false);
      dispatch(changeUserBalanceThunk(props.user.id, balance));
    }
    else setIsInChanging(true)
    
  };

  const onRoleChange = (role) => {
    dispatch(changeUserAdminStatusThunk(props.user.id, role));
  };

  const onBalanceChange = (event) => {
    if (isInChanging) {
      setBalance(event.target.value);
    }
  };

  const [ordersVisible, setOrdersVisible] = useState(false);

  const [isInChanging, setIsInChanging] = useState(false);
  const [balance, setBalance] = useState(props.user.balance);

  return (
    <>
    <div className={s.container}>
      <div className={s.admin_users_header}>
        <Button
          size="small"
          onClick={() => setIsInChanging(true)}
          type="dashed"
          style={{ marginRight: "30px", marginLeft: "30px" }}
        >
          Редактировать
        </Button>
        <div className={s.changeble_content}>
            <div style={{backgroundColor: "#fff", borderRadius: 6}}>
          <Button onClick={props.user.is_admin == false?() => onRoleChange(true):() => onRoleChange(false)} type="primary" disabled={!isInChanging}>
            {props.user.is_admin == false ? "Выдать Админку" : "Забрать Админку"}
          </Button>
          </div>
          <div className={s.item}>
            <div className={s.name}>Name: {props.user.username}</div>
            <div>Role: {props.user.is_admin?"ADMIN":"USER"}</div>
          </div>
          <div className={s.item}>
            <div>Balance:</div>
            <input
              style={{ width: "80px" }}
              className={isInChanging ? s.unlocked_field : s.locked_field}
              value={isInChanging ? balance : props.user.balance}
              onChange={onBalanceChange}
            />
          </div>
        </div>
        <Button onClick={onSave} type="dashed">
          Сохранить
        </Button>
      </div>
      <Button size="small" onClick={() => setOrdersVisible(!ordersVisible)}>
        Показать/Скрыть Заказы
      </Button>
      <div
        style={ordersVisible ? null : { display: "none" }}
        className={s.orders}
      >
      </div>
    </div>
    <div >
        <div className={s.ctg_item}>
          <div className={s.user_email}>{props.user.email?props.user.email:"@"+props.user.telegram_username}</div>
          <div className={s.user_status}>{props.user.is_admin?"ADMIN":"USER"}</div>
          <button onClick={props.user.is_admin == false?() => onRoleChange(true):() => onRoleChange(false)} className={s.footer_btn}>{props.user.is_admin == false ? "Выдать Админку" : "Забрать Админку"}</button>
          {isInChanging?<input
              style={{ width: "80px" }}
              className={s.unlocked_field}
              value={balance}
              onChange={onBalanceChange}
            />:<div className={s.user_balance}>{props.user.balance} <span>₽</span></div>}
          <img onClick={onSave} style={{cursor: "pointer"}} className={s.edit} src={edit} alt="edit" />
        </div>
      </div>
    </>
  );
};

export default AdminUser;
