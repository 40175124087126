import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Admin from "./components/Admin/Admin";
import HomePage from "./pages/HomePage";
import { useDispatch } from "react-redux";
import AdminProducts from "./components/Admin/AdminProducts/AdminProducts";
import AdminCategories from "./components/Admin/AdminCategories/AdminCategories";
import AdminUsers from "./components/Admin/AdminUsers/AdminUsers";
import { useEffect } from "react";
import { getMeThunk } from "./Redux/authReduser";
import {
  getCategories,
  getProducts,
} from "./Redux/productsReduser";
import { Helmet } from "react-helmet";
import RulesPage from "./pages/RulesPage/RulesPage";
import FAQPage from "./pages/FAQPage/FAQPage";
import Policy from "./pages/Policy/Policy";
import ContractOffer from "./pages/ContractOffer/ContractOffer";
import FundPage from "./pages/FundPage/FundPage";
import AboutUs from "./pages/AboutUs/AboutUs";
import { refreshToken } from "./utils/refreshToken";

function App() {
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(getMeThunk());
    dispatch(getProducts(0));
    dispatch(getCategories());
  }, []);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>Greedy Market</title>
      </Helmet>
      <div>
        <Routes>
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/admin/products" element={<AdminProducts />} />
          <Route path="/admin/categories" element={<AdminCategories />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/rules" element={<RulesPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/contract_offer" element={<ContractOffer />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/invoice" element={<FundPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
