import { refreshToken } from "../utils/refreshToken"



export const adminAPI = {
    changeUserBalance(id, balance){
        return refreshToken("admin/balance", "put", {token: localStorage.getItem("token"), id, balance})
        .then(res => res)
        .catch(e => e)
    },
    changeUserAdminStatus(id, admin_status){
        return refreshToken("admin/admin", "put", {token: localStorage.getItem("token"), id, admin_status}) 
        .then(res => res)
        .catch(e => e)
    },
    getUsers(offset){
        return refreshToken("admin/users", "get", {token: localStorage.getItem("token"), offset})
        .then(res => res)
        .catch(e => e)
    },
    getStatistics(){
        return refreshToken("admin/statistics", "get", {token: localStorage.getItem("token")})
        .then(res => res)
        .catch(e => refreshToken(e, this.getStatistics))
    },
    searchUsers(username){
        return refreshToken("admin/users", "get", {token: localStorage.getItem("token"), username})
        .then(res => res)
        .catch(e => e)
    },
    getAnalytics(delta){
        return refreshToken("admin/analytics", "get", {token: localStorage.getItem("token"), delta})
        .then(res => res)
        .catch(e => e)
    },
}