import React from "react";
import s from "./../Register.module.css";
import { Input, Form } from "antd";
import "antd/dist/reset.css";
import "../../../index.css";
import { authAPI } from "../../../API/authAPI";
import { message } from "antd";

const EmailConfirmation = (props) => {
  
  const [messageApi, contextHolder] = message.useMessage();

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Неправильный код",
    });
  };

  const onFinish = async (values) => {
    const response = await authAPI.confirmEmail(values.emailConfirmation);
    if (response.status === 200) {
      props.setEmailConfirmation(false);
      props.setLogin(true);
    } else {
      error();
    }
  };

  return (
    <div>
      {contextHolder}
      <div
        onClick={() => props.setEmailConfirmation(false)}
        className={s.modal_back}
      ></div>
      <div className={s.window}>
        <div className={s.reg_title}>Введите код который пришел на ваш Email</div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="emailConfirmation"
            rules={[
              {
                required: true,
                message: "Please input email confirmation code!",
              },
            ]}
          >
            <Input placeholder="Confirmation Code" className={s.user_input} />
          </Form.Item>

          <Form.Item>
          <button className={s.login_form_button_cont} type="submit"><div className={s.login_form_button}>Подтвердить</div></button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EmailConfirmation;
