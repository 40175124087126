import s from "./CatalogItem.module.css";
import { message } from "antd";
import { useEffect, useState } from "react";
import Purchase from "../../Purchase/Purchase";
import { productsAPI } from "../../../API/productsAPI";
import { useSelector } from "react-redux";
import loop from "../../../images/loopSearch.png"

const CatalogItem = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const error = () => {
    messageApi.open({
      type: "error",
      content: "Сначала авторизируйтесь",
    });
  };

  const [count, setCount] = useState(0);

  useEffect(() => {
    productsAPI
      .getProductsCount(props.product.id)
      .then((res) => setCount(res.data));
  }, []);

  const [isDescription, setIsDescription] = useState(false);
  const [buy, setBuy] = useState(false);
  const isAuth = useSelector((state) => state.Auth.isAuth);

  const onBuy = () => {
    if (isAuth) {
      window.scrollTo(0, 0);
      setBuy(true);
    }
    if (!isAuth) {
      error();
    }
  };
  return (
    <>
      {contextHolder}
      {buy ? (
        <Purchase
          setError={props.setError}
          count={count}
          setCount={setCount}
          setName={props.setName}
          setPrice={props.setPrice}
          setAmount={props.setAmount}
          setDownloadUrl={props.setDownloadUrl}
          setSucPay={props.setSucPay}
          product={props.product}
          setPurchase={setBuy}
        />
      ) : null}
      <div className={s.ctg_item}>
        <img
          onClick={() => setIsDescription(!isDescription)}
          className={s.tiktok_img}
          src={props.img === loop?loop:"data:image/png;base64," + props.img}
          alt="img"
        />
        <div
          onClick={() => setIsDescription(!isDescription)}
          className={s.item_title}
        >
          {props.product.title}
        </div>
        <div className={s.item_amount}>{count}</div>
        <div className={s.item_price}>
          {props.product.price} <span className={s.price_item}>₽</span>
        </div>
        <button onClick={onBuy} className={s.buy}>
          Купить
        </button>
        {isDescription ? (
          <>
            <div className={s.item_description}></div>
            <div className={s.item_description}>
              <div style={{color: "#FD125A", fontSize: "16px", fontWeight: "600", marginBottom: "1rem"}}>Описание товара</div>
              {props.product.description}
              {props.product.link?<div><button className={s.readFull}><a target="blank" className={s.fullLink} href={props.product.link}>Читать полностью</a></button></div>:null}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default CatalogItem;
