import s from "./Footer.module.css"
import sale from "../../images/discount.png"
import LOGO from "../../images/LOGO54.png"
import { useNavigate } from "react-router-dom"


const Footer = () => {

  const navigate = useNavigate()

  return (
    <div className={s.container}>
      <div className={s.footer_inner}>
        <div
          className={s.logo}>
            <div className={s.LOGO123}>
             <img  src={LOGO} alt="" />
             </div>
          <div className={s.logo_inf}>GreedyMarket-Магазин аккаунтов </div>
          <div className={s.logo_inf}> 2022-2023 © GreedyMarket</div>
        </div>
        <div className={s.cont}>
        <div className={s.footer_btn_right}>
          <img src={sale} alt="sale"  className={s.sale1}/>
            Скидка за отзыв
            </div>
          </div>
        <div className={s.footer_item}>
          <div className={s.chz}><a className={s.link} href='#'>Главная</a></div>
          <div className={s.chz}><a className={s.link} target='blank' href='https://t.me/GreedyMarketHELPbot'>Поддержка</a></div>
          <div className={s.chz}><button className={s.rules} onClick={() => navigate("/faq")}>FAQ</button></div>
        </div>
        <div className={s.footer_item}>
          <div className={s.chz}><button className={s.rules} onClick={() => navigate("/policy")}>Политика обработки данных</button></div>
          <div className={s.chz}><button className={s.rules} onClick={() => navigate("/contract_offer")}>Договор оферты</button></div>
          <div className={s.chz}><button className={s.rules} onClick={() => navigate("/about_us")}>О нас</button></div>
        </div>
        <div className={s.footer_item}>
          <div className={s.chz}><a className={s.link} target="blank" href="https://t.me/Greedy_Projects">Наши проекты</a></div>
          <div className={s.chz}><a className={s.link} target="blank" href="https://t.me/GreedyMarketReviews">Отзывы</a></div>
          <div className={s.chz}><button className={s.rules} onClick={() => navigate("/rules")}>Правила</button></div>
        </div>
        <div>
          <div className={s.footer_btn}>
          <img src={sale} alt="sale"  className={s.sale1}/>
            Скидка за отзыв
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;