import { Form, Input, message } from "antd";
import close from "../../images/close.png"
import s from "./Purchase.module.css"
import { authAPI } from "../../API/authAPI";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMeThunk } from "../../Redux/authReduser";

const Purchase = (props) => {
  const dispatch = useDispatch()
  props.setName(props.product.title)
  const [total, setTotal] = useState(0)
  const [price, setPrice] = useState(0)
  const [promo, setPromo] = useState(false)
  const [promocode, setPromocode] = useState(null)
  const {setPurchase} = props;

  const [messageApi, contextHolder] = message.useMessage();
  const error = (content) => {
    messageApi.open({
      type: "error",
      content: `${content}`
    });
  };

  const onFinish = (values) => {
    console.log(values)
    authAPI.buyProduct(props.product.id, values.amount, values.promocode?.substring(1))
    .then(res => {
      if(res.status === 200){
        const blob = new Blob([JSON.stringify(res.data)], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        props.setDownloadUrl(url)
        props.setPrice(price)
        props.setAmount(values.amount)
        setPurchase(false)
        props.setSucPay(true)
        props.setCount(props.count-1)
        dispatch(getMeThunk())
      }
      if(res.response.status === 402){
        setPurchase(false)
        props.setError(true)
      }
      if(res.response.status === 404){
        error("Недостаточное количество товара")
      }
      else{
        error("Ошибка")
      }
    })
  };

  useEffect(() => {
    setPrice(total*props.product.price)
  
  },[total])
  useEffect(() => {
    if(promocode === "#GREEDY"){
      setPrice(parseFloat((total*props.product.price/20*19).toFixed(2)))
    }
    else{
      setPrice(total*props.product.price)
    }
  },[promocode, total])

  

  return (

    <div>
      {contextHolder}
      <div onClick={()=>setPurchase(false)} className={s.modal_back}></div>
      <div className={s.window}>
        <img  onClick={()=>setPurchase(false)} src={close} alt="close" className={s.close_img} />
        <div className={s.reg_title1}>Приобретение</div>
        <div className={s.reg_title}>{props.product?.title}</div>
        <div className={s.black_title}>Количество товара</div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
            <> 
            
            <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please input amount!",
                  },
                ]}
              >
                <Input
                onChange={(e) => setTotal(e.target.value)}
                  className={s.password_input}
                  placeholder=""
                />
              </Form.Item>
            {promo?
            <>
            <div className={s.black_title}>Промокод</div>
            <Form.Item
                name="promocode"
                rules={[
                  {
                    required: true,
                    message: "Please input promocode!",
                  },
                ]}
              >
            <Input
                onChange={(e) => setPromocode(e.target.value)}
                  className={s.password_input}
                  placeholder=""
                /></Form.Item></>:<div onClick={() => setPromo(true)} className={s.promo}>У меня есть промокод</div>}
            <div className={s.black_title1}>Итого к оплате</div>
             <div className={s.balance}>{price}<div style={{color:"#FD125A"}}> ₽</div></div>
              <Form.Item>
              <button className={s.login_form_button}>
                Перейти к оплате
                </button>
               
              </Form.Item>
            </>
        </Form>
      </div>
    </div>
  );
};

export default Purchase;
