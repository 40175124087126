import { useState } from "react"
import s from "./AdminCategorie.module.css"
import { useDispatch } from "react-redux"
import { Button, Form, Input, Modal } from "antd"
import { deleteCategoryThunk, editCategoryThunk } from "../../../../Redux/productsReduser"

const AdminCategorie = (props) => {

    const dispatch = useDispatch()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleOk = () => {
        setIsModalOpen(false);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const onFinish = (values) => {
        dispatch(editCategoryThunk(props.categorie.id, props.categorie.title, props.categorie.color, values.title))
        handleCancel()
      }
      const onDelete = () => {
        dispatch(deleteCategoryThunk(props.categorie.id))
        handleCancel()
      }
    return(
        <>
        <div onClick={showModal} style={{backgroundColor: `${props.categorie.color}`, cursor: "pointer"}} className={s.ctg_item_add1}>{props.categorie.title}</div >
        <Modal footer={[]} title="Изменение категории" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form onFinish={onFinish}>
                <Form.Item name="title" rules={[{required: true}]}>
                    <Input placeholder="Новое название" />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit">Подтвердить</Button>
                </Form.Item>
            </Form>
            <Button onClick={onDelete} danger>Удалить</Button>
        </Modal>
        </>
    )
}

export default AdminCategorie