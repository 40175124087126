import { Suspense, lazy } from 'react';
import AdminProducts from "./AdminProducts/AdminProducts";
import AdminUsers from "./AdminUsers/AdminUsers";
import { NavLink, useNavigate } from "react-router-dom";
import s from "./Admin.module.css";
import AdminCategories from "./AdminCategories/AdminCategories";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button } from "antd";
import AdminAddAccounts from "./AdminAddAccounts/AdminAddAccounts";
const AdminStatistic = lazy(() => import('./AdminStatistic/AdminStatistic'));


const Admin = () => {
  const [category, setCategory] = useState(false);
  const [products, setProducts] = useState(false);
  const [users, setUsers] = useState(false);
  const [accounts, setAccounts] = useState(false)
  const [statistic, setStatistic] = useState(true)


  const modalCategoryChanger = (bool) => {
    setProducts(false);
    setAccounts(false)
    setUsers(false);
    setStatistic(false);
    setCategory(bool);
  };

  const modalProductsChanger = (bool) => {
    setCategory(false);
    setAccounts(false)
    setUsers(false);
    setStatistic(false);
    setProducts(bool);
  };

  const modalUsersChanger = (bool) => {
    setProducts(false);
    setAccounts(false)
    setCategory(false);
    setStatistic(false);
    setUsers(bool);
  };

  const modalAccountsChanger = (bool) => {
    setProducts(false);
    setCategory(false);
    setUsers(false)
    setStatistic(false);
    setAccounts(bool);
  };

  const modalStatisticChanger = (bool) => {
    setProducts(false);
    setCategory(false);
    setUsers(false);
    setAccounts(false)
    setStatistic(bool);
  };


 

  const isAdmin = useSelector((state) => state.Auth.isAdmin);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAdmin) navigate("/");
  }, []);

  return (
    <div>
      <div className={s.con}>
        <div>
          <div className={s.menu}>
            <div className={s.logo}>
           
              <div className={s.logo1}>Greedy</div>
              <div className={s.logo2}>Panel</div>
            </div>
            <div className={s.ctg_sec}>
              <div className={s.ctg_title} onClick={()=>{modalCategoryChanger(true)}}>Категории</div>
            </div>
            <div className={s.user_sec}>
              <div className={s.ctg_title} onClick={()=>{modalUsersChanger(true)}}>Пользователи</div>
            </div>
            <div className={s.user_sec}>
              <div className={s.ctg_title} onClick={()=>{modalProductsChanger(true)}}>Продукты</div>
            </div>
            <div className={s.user_sec}>
              <div className={s.ctg_title} onClick={()=>{modalAccountsChanger(true)}}>Добавить аккаунты</div>
            </div>
            <div className={s.user_sec}>
              <div className={s.ctg_title} onClick={()=>{modalStatisticChanger(true)}}>Статистика</div>
            </div>
          </div>
        </div>
        <div className={s.block_item}>
          <div className={s.top}>
            <button onClick={() =>navigate("/")} className={s.buy}>Выход</button>
            <div className={s.padding}></div>
          </div>
          <div className={s.main}>
           {category && <AdminCategories />}
           {users && <AdminUsers />}
           {products && <AdminProducts />}
           {accounts && <AdminAddAccounts />}
           {statistic && <Suspense fallback={"loading"}>< AdminStatistic /></Suspense>}
 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
