import { Form, Button, Modal } from "antd";
import { productsAPI } from "../../../../../API/productsAPI";
import { useState } from "react";
import s from "./Modal.module.css"

const AccountModal = (props) => {
  const [file, setFile] = useState();
  const onFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = () => {
    const formData = new FormData();
    formData.append("accounts", file);
    productsAPI.addOrderItems(props.product.id, formData);
    handleCancel()
  };
  const [form] = Form.useForm();
  return (
    <div className={s.btn}>
      <Button onClick={showModal}>{props.product.title}</Button>
      <Modal
        title="Добавить аккаунты"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Title"
            name="file"
            rules={[
              {
                message: "Please sent file!",
              },
            ]}
          >
            <input onChange={onFileSelect} type="file" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Подтвердить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AccountModal;
