import s from "./Telegram.module.css";
import { Input, Form, message } from "antd";
import close from "../../images/close.png";
import { authAPI } from "../../API/authAPI";



const TelegramBinding = (props) => {
  const { setTg } = props;

  const [messageApi, contextHolder] = message.useMessage();
  const error = (content) => {
    messageApi.open({
      type: "error",
      content: `${content}`,
    });
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно',
    });
  };

  const onFinish = async (values) => {
    const phoneNumberRegExp = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
    const URLRegExp = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
    if (phoneNumberRegExp.test(values.telegram_username)){
      error("Некорректное имя пользователя")
      return
    }
    if (URLRegExp.test(values.telegram_username)){
      const index = values.telegram_username.lastIndexOf("/") + 1
      values.telegram_username = values.telegram_username.slice(index)
    }
    if(values.telegram_username.length <= 0){
      error("Некорректное имя пользователя")
      return
    }
    if(props.isProfile){
      if(Array.from(values.telegram_username)[0] === '@'){
        error("Введите имя пользователя без символа @ в начале")
        return
      }
      const res = await authAPI.telegram(values.telegram_username)
      if(res.status === 200){
        success()
        setTimeout(() => {
          props.setTg(false)
        }, 1200);
      }
      else{
        error("Ошибка")
      }
    }
    else {
      if(Array.from(values.telegram_username)[0] === '@'){
        error("Введите имя пользователя без символа @ в начале")
        return
      }
      const res = await authAPI.tgRegister(values.telegram_username)
      if (res.status === 200) {
        props.setTg(false)
        props.setLogin(true)
      }
      if (res.response.status === 401) {
        error("Пользователь с таким именем уже существует")
      }
      else error("Ошибка")
    }
  };

  return (
    <div>
      {contextHolder}
      <div onClick={() => setTg(false)} className={s.modal_back}></div>
      <div className={s.window}>
        <img
          onClick={() => setTg(false)}
          src={close}
          alt="close"
          className={s.close_img}
        />
        <div className={s.reg_title}>Войдите с помощь Telegram</div>
        <span className={s.add_title}>Войдите с помощь Telegram </span>
        <span className={s.add_title}>используя имя пользователя Telegram</span>
        <div className={s.black_title}>Введите имя пользователя Telegram</div>
        <Form onFinish={onFinish}>
          <Form.Item
            name="telegram_username"
            rules={[
              {
                required: true,
                message: "Please input your telegram username!",
              },
            ]}
          >
            <Input className={s.password_input} type="" placeholder="" />
          </Form.Item>
          <Form.Item>
            <button type="submit" className={s.login_form_button}>
              Подтвердить
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default TelegramBinding;
