import { useNavigate } from "react-router-dom";
import s from "./Policy.module.css";

const Policy = () => {
  const navigate = useNavigate();
  return (
    <div className={s.container}>
      <div className={s.head}>Политика обработки персональных данных</div>
      <div className={s.main}>
        Настоящая Политика конфиденциальности персональной информации (далее —
        Политика) действует в отношении всей информации, которую сайт{" "}
        <a className={s.link} onClick={() => navigate("/")}>
          GreedyMarket.org
        </a>
        может получить о пользователе во время использования им сайта.
        Использование Сайта означает безоговорочное согласие пользователя с
        настоящей Политикой и указанными в ней условиями обработки его
        персональной информации и персональных данных; в случае несогласия с
        этими условиями пользователь должен воздержаться от использования
        данного ресурса.<br/> Администрация ресурса, оставляет за собой право,
        прекратить предоставление услуг, пользователю в одностороннем порядке,
        без компенсации материальных ресурсов пользователю, в одностороннем
        порядке, без объяснения причин отказа в обслуживании.
      </div>
      <div className={s.item}>
        1. Персональная информация пользователей, которую получает и
        обрабатывает Сайт:
      </div>
      <div className={s.item_c1}>
      1.1 В рамках настоящей Политики под «персональной информацией пользователя» понимаются:
      </div>
      <div className={s.item_c2}>
      1.1.1 Персональная информация, которую пользователь предоставляет о себе самостоятельно при оставлении заявки, или в ином процессе использования Сайта;
      </div>
      <div className={s.item_c2}>
      1.1.2 Данные, которые автоматически передаются Сайтом в процессе его использования с помощью, установленного на устройстве пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере пользователя (или иной программе, с помощью которой осуществляется доступ к сайту), время доступа, адрес запрашиваемой страницы;
Электронная почта,
Содержимое файлов Cookie;
      </div>
      <div className={s.item_c1}>
      1.2 Настоящая Политика применима только к Сайту и не контролирует и не несет ответственность за сайты третьих лиц, на которые пользователь может перейти по ссылкам, доступным на Сайте. На таких сайтах у пользователя может собираться или запрашиваться иная персональная информация, а также могут совершаться иные действия;
 Сайт в общем случае не проверяет достоверность персональной информации, предоставляемой пользователями, и не осуществляет контроль за их дееспособностью. Однако Сайт исходит из того, что пользователь предоставляет достоверную и достаточную персональную информацию по вопросам, предлагаемым в формах настоящего ресурса, и поддерживает эту информацию в актуальном состоянии.
      </div>
    </div>
  );
};

export default Policy;
