import { Form, Input, Select, message } from "antd";
import s from "./BalanceTransferForm.module.css"
import close from "../../../images/close.png"
import { paymentsAPI } from "../../../API/paymentsAPI";
import { useDispatch } from "react-redux";
import { getMeThunk } from "../../../Redux/authReduser";

const BalanceTransferForm = (props) => {

  const dispatch = useDispatch()
  const { setTransfer } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const error = (content) => {
    messageApi.open({
      type: "error",
      content: `${content}`,
    });
  };
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно',
    });
  };
  const onFinish = async (values) => {
    const res = await paymentsAPI.transferBalanse(values.telegram_id, values.amount, values.bot)
    if(res.status === 200){
      success()
      dispatch(getMeThunk())
      setTimeout(() => {
        setTransfer(false)
      }, 1200);
    }
    if(res.response.status === 400){
      error("Неправильный id")
    }
    if(res.response.status === 402){
      error("Недостаточно средств")
    }
  };

  return (

    <div>
      {contextHolder}
      <div onClick={() => setTransfer(false)} className={s.modal_back}></div>
      <div className={s.window}>
        <img onClick={() => setTransfer(false)} src={close} alt="close" className={s.close_img} />
        <div className={s.reg_title}>Перевести баланс в Telegram бота</div>
        <span className={s.add_title}>Выберите  Telegram бота и</span>
        <span className={s.add_title}> введите нужную сумму</span>


        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <>
            <div className={s.black_title}>Введите Telegram id</div>
            <Form.Item
              name="telegram_id"
              rules={[
                {
                  required: true,
                  message: "Telegram id находится в боте в профиле",
                },
                {
                  pattern: /^\d+$/,
                  message: "Telegram id может содержать только цифры",
                }
              ]}
            >

              <Input placeholder="telegram id" className={s.user_input} />
            </Form.Item>

            <div className={s.black_title1}>Выберите бота</div>
            <Form.Item 
            name="bot"
            rules={[
              {
                required: true,
                message: "Please choose bot",
              },
            ]}
             >
            <Select
              className={s.select}
              defaultValue="Выберите бота"
              style={{ width: 290 }}
              allowClear
              options={[
                { value: 'sms', label: 'Greedy SMS'},
                { value: 'shop', label: 'Greedy SHOP'},
                { value: 'proxy', label: 'Greedy PROXY'},
                { value: 'GreedyGarant', label: 'Greedy GARANT'},
            ]}
            />
            </Form.Item>


            <div className={s.data_operations}>

            </div>
            <div className={s.black_title}>Введите сумму</div>
            <Form.Item
              name="amount"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >

              <Input placeholder="" className={s.user_input} />
            </Form.Item>

            <button className={s.submit_btn} type="submit"><div className={s.login_form_button}>
              Подтвердить
            </div></button>



          </>
        </Form>
      </div>
    </div>
  );
};

export default BalanceTransferForm;
