import { useDispatch, useSelector } from "react-redux"
import AdminUser from "./AdminUser/AdminUser"
import s from "./AdminUsers.module.css"
import { Pagination } from "antd"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { getUsersThunk } from "../../../Redux/usersReduser"
import { adminAPI } from "../../../API/adminAPI"

const AdminUsers = () => {


    const [countUsers ,setCountUsers] = useState()
    const [searchedUsers, setSearchedUsers] = useState([])
    const [page, setPage] = useState(1);
    const isAdmin = useSelector((state) => state.Auth.isAdmin)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        if (!isAdmin) navigate("/")
        dispatch(getUsersThunk(0))
    }, [])
    useEffect(() => {
        adminAPI.getStatistics()
        .then((res) => {
        setCountUsers(res.data.count_users);
        })
        dispatch(getUsersThunk(page - 1));
      }, [page]);

    const users = useSelector((state) => state.Users.users)

    const onSearch = (value) => {
        adminAPI
          .searchUsers(value)
          .then((res) => setSearchedUsers(res.data));
          console.log(searchedUsers)
      };

    return (
        <div className={s.container}>
            <div className={s.users_area}>
                <div className={s.users_title}>Последние пользователи</div>
            </div>
            <div className={s.main_find}>
            <input
                onChange={(e) => onSearch(e.target.value)}
                className={s.find}
                type="text"
                placeholder="Поиск пользователей"
            />
            </div>
            {searchedUsers?.length > 0? searchedUsers.map(user => <AdminUser key={user.id} user={user} />):users.map((user) => <AdminUser key={user.id} user={user} />)}
            <div className={s.pagination}>
        <Pagination
          onChange={(page) => setPage(page)}
          current={page}
          defaultPageSize={20}
          defaultCurrent={1}
          total={countUsers}
          showSizeChanger={false}
        />
      </div>
        </div>
    )
}

export default AdminUsers