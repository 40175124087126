import { Collapse } from "antd";
import { useEffect, useState } from "react";
import { productsAPI } from "../../../../API/productsAPI";
import Modal from "./Modal/Modal";
import s from "./AdminAddAccCategory.module.css"

const { Panel } = Collapse;

const AdminAddAccCategory = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState();

  useEffect(() => {
    productsAPI
      .getProductsByCategory(props.cat.id, props.cat.title, props.cat.color)
      .then((res) => setProducts(res.data));
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Collapse>
        <Panel header={props.cat.title} key="1">
          <div className={s.btns}>
            {products?.map((prod) => (
              <Modal
                key={prod.id}
                product={prod}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              />
            ))}
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default AdminAddAccCategory;
