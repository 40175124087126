import s from './About.module.css';
import img1 from "../../images/3-1.png";
import img2 from "../../images/2-22.png";
import img3 from "../../images/1-53.png";
import headphones from "../../images/headphones.png";
import { useState } from 'react';
import BalanceTransferForm from '../BalanceTransfer/BalanceTransferForm/BalanceTransferForm';
import Profile from '../Profile/Profile';
import Fund from '../../Fund/Fund';
import Purchase from '../Purchase/Purchase';
import SucPay from '../SucPay/SucPay';

const About = () => {

   const [transfer,setTransfer] = useState(false)
   const [profile,setProfile] = useState(false)
   const [fund,setFund] = useState(false)
   const [purchase,setPurchase] = useState(false)
   const [sucPay,setSucPay] = useState(false)

   const modalTransferChanger = (bool) =>{
    setTransfer(bool)
   }

   const modalProfileChanger = (bool) =>{
    setProfile(bool)
   }

   const modalFundChanger = (bool) =>{
    setFund(bool)
   }

   const modalPurchaseChanger = (bool) =>{
    setPurchase(bool)
   }

   const modalsucPayChanger = (bool) =>{
    setSucPay(bool)
   }


    return (
        <>
        {transfer && <BalanceTransferForm setTransfer = {modalTransferChanger}/>}
        {profile && <Profile setProfile = {modalProfileChanger}/>}
        {fund && <Fund setFund= {modalFundChanger} />}
        {purchase && <Purchase setPurchase = {modalPurchaseChanger}/>}
        {sucPay && <SucPay setSucPay = {modalsucPayChanger}/>}
       
            <div className={s.about_items}>
                <div className={s.about_item}>
                    <div className={s.about_text}>
                        <div className={s.about_title}>Репутация</div>
                        <div className={s.about_title_inf}>Более 2 лет на рынке</div>
                    </div>
                    <img src={img1} alt="img1" />
                </div>
                <div className={s.about_item2}>
                    <div className={s.about_text}>
                        <div className={s.about_title}>Поддержка</div>
                        <div className={s.about_title_inf}>Ответим на вопросы</div>
                    </div>
                    <img src={img2} alt="img2" />
                </div>
                <div className={s.about_item}>
                    <div className={s.about_text} style={{marginRight: "0.8rem"}}>
                        <div className={s.about_title}>Приятные цены</div>
                        <div className={s.about_title_inf}>Копейка рубль бережёт</div>
                    </div>
                    <img src={img3} alt="img3" />
                </div>
            </div>
           
            <a className={s.link} target='blank' href='https://t.me/GreedyMarketHELPbot'><div className={s.sup}>
                   <img src={headphones} alt="sup" />
                   <div className={s.sup_text}>Поддержка</div> 
                </div></a>
               
              
      
        </>
    )
}

export default About;