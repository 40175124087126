import React, { useState } from "react";
import s from "./Register.module.css";
import { Input, Form, message } from "antd";
import "antd/dist/reset.css";
import "../../index.css";
import close from "../../images/close.png"
import { authAPI } from "../../API/authAPI";
import { useNavigate } from "react-router-dom";


const Register = (props) => {

  const [messageApi, contextHolder] = message.useMessage();
  const error = (content) => {
    messageApi.open({
      type: "error",
      content: `${content}`,
    });
  };

  const openTgBinding = () => {
    props.setRegister(false)
    props.setTgBinding(true)
  }

  const onFinish = async (values) => {
    const res = await authAPI.register(values.email, values.username, values.password)
    if (res.status === 200) {
      props.setRegister(false)
      props.setEmailConfirmation(true)
    }
    if (res.response.status === 401) {
      error("Пользователь с таким email уже существует")
    }
    else error("Ошибка")
  }

  const [focus, setFocus] = useState(false)
  const navigate = useNavigate()
  return (
    <div>
      {contextHolder}
      <div
        onClick={() => props.setRegister(false)}
        className={s.modal_back}
      ></div>
      <div className={s.window}>
        <img onClick={() => props.setRegister(false)} src={close} alt="close" className={s.close_img} />
        <div className={s.reg_title}>Регистрация</div>
        <span className={s.add_title}>Заполните все необходимые поля </span>
        <span className={s.add_title}> для регистрации</span>
        <Form
          name="normal_login"
          className={s.login_form}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div className={s.black_title}>Имя пользователя</div>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              className={s.user_input}

              placeholder="Username"
            />
          </Form.Item>
          <div className={s.black_title}>Электронная почта</div>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              className={s.user_input}

              placeholder="Email"
            />
          </Form.Item>
          <div className={s.black_title}>Пароль</div>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            style={focus?{border: "1px solid  #FD125A",
              boxShadow: "none", caretColor: "#FD125A"}:null}
              className={s.user_input}

              placeholder="Password"
            />
          </Form.Item>



          <button className={s.login_form_button1}>
            Зарегистрироваться
          </button>
          <div className={s.add_title2}>Нажимая кнопку Зарегистрироваться вы соглашаетесь с нашими
          <span onClick={() => navigate("/rules")} style={{ color: "#FD125A", marginLeft: 4, marginRight: 4 ,cursor: "pointer"}}>правилами</span> указанными в соответственном <br /> разделе на сайте </div>
          <div className={s.black_title}>Или</div>
          <button onClick={openTgBinding} className={s.login_form_button}>
            Регистрация c помощью Telegram
          </button>

        </Form>
        
          

     
      </div>
    </div>
  );
};
export default Register;
