import { Button, Form, Image, Input } from "antd";
import s from "./Fund.module.css";
import close from "../images/close.png";
import lava from "../images/lava.png";
import freekassa from "../images/freekassa.png";
import enot from "../images/enot.png";
import paypalych from "../images/payeer.png";
import aaio from "../images/aaio.png"
import p2pKassa from "../images/p2pKassa.png"
import { useState } from "react";
import { paymentsAPI } from "../API/paymentsAPI";

const Fund = (props) => {
  const [selected, setSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const { setFund } = props;
  const onFinish = (values) => {
      setIsLoading(true)
      paymentsAPI
      .createInvoice(values.amount, selected)
      .then((res) => {
        window.location.href = res.data
      })
      .finally(() => setIsLoading(false))
  };
  const fundClose = () => {
    props.setErrorFund(false);
    setFund(false);
  };
  console.log(isLoading)
  return (
    <div>
      <div onClick={fundClose} className={s.modal_back}></div>
      <div className={s.window} style={{top: "55%"}}>
        <img
          onClick={fundClose}
          src={close}
          alt="close"
          className={s.close_img}
        />
        <div className={s.reg_title}>Пополнение баланса</div>
        <span className={s.add_title}>
          Введите сумму и выберите способ оплаты{" "}
        </span>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <>
            <div className={s.black_title}>Сумма пополнения</div>

            <div className={s.wrapper}>
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input className={s.user_input} placeholder="" />
              </Form.Item>
            </div>

            <div>
              <div className={s.black_title}>Способ оплаты</div>
              <div className={s.plata}>
                {/* <div
                  className={s.plata_container}
                  style={
                    selected !== "lava"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("lava")}
                >
                  <img src={lava} className={s.plata_img} alt="lava" />

                  <div className={s.img_label}>LAVA</div>
                </div> */}
                <div
                  className={s.plata_container}
                  style={
                    selected !== "enot"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("enot")}
                >
                  <img src={enot} className={s.plata_img} alt="enot" />

                  <div className={s.img_label}>ENOT</div>
                </div>
                <div
                  className={s.plata_container}
                  style={
                    selected !== "freekassa"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("freekassa")}
                >
                  <img
                    className={s.plata_img}
                    src={freekassa}
                    alt="freekassa"
                  />

                  <div className={s.img_label}>FREEKASSA</div>
                </div>
                <div
                  className={s.plata_container}
                  style={
                    selected !== "payeer"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("payeer")}
                >
                  <img
                    className={s.plata_img}
                    src={paypalych}
                    alt="payeer"
                  />

                  <div className={s.img_label}>PAYEER</div>
                </div>
                <div
                  className={s.plata_container}
                  style={
                    selected !== "aaio"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("aaio")}
                >
                  <img
                    className={s.plata_img}
                    src={aaio}
                    alt="aaio"
                  />

                  <div className={s.img_label}>AAIO</div>
                </div>
                <div
                  className={s.plata_container}
                  style={
                    selected !== "p2pkassa"
                      ? { opacity: "0.5" }
                      : { borderColor: "#FD125A" }
                  }
                  onClick={() => setSelected("p2pkassa")}
                >
                  <img
                    className={s.plata_img}
                    src={p2pKassa}
                    alt="p2pkassa"
                  />

                  <div className={s.img_label}>P2PKassa</div>
                </div>
              </div>
            </div>
            <h3 className={s.payment_info}>Платежные системы отличаются методами платежей, минимальной сумме платежа, а также комиссией.</h3>
            <div className={s.description}>
              <p>
                <span>FreeKassa:</span>
                <br />
                VISA RUB (9%) — от 50₽
                <br />
                MasterCard RUB (9%) — от 50₽
                <br />
                МИР (9%) — от 50₽
                <br />
                СБП (10%)  — от 50₽
                <br />
                Онлайн банк (9%) — от 1000₽
                <br />
                SteamPay (5%) — от 50₽
                <br />
                ЮMoney (9%) — от 1000₽
                <br />
                VISA/MasterCard KZT (10%) — от 4000₸
                <br />
                VISA/MasterCard UAH (15%) — от 500₴
              </p>
              <p>
                <span>P2PKassa:</span>
                <br />
                Карты Россия (5%) — от 100₽
                <br />
                ЮMoney (5%) — от 100₽
                <br />
                СБП (5%) — от 100₽
                <br />
                Карты Казахстан (10%) — от 250₽
                <br />
                Карты Украина (10%) — от 250₽
                <br />
                Карты Узбекистан (10%) — от 450₽
                <br />
                Карты Беларусь (10%) — от 1000₽
                <br />
                Карты Азербайджан (10%) — от 500₽
                <br />
              </p>
              <p>
                <span>Aaio:</span>
                <br />
                Карты Россия (7.5%) — от 100₽ 
                <br />
                СБП (10%) — от 100₽
                <br />
                Карты Украина (24%) — от 100₽
                <br />
                Оплата скинами (3%) — от 300₽
                <br />
                AdvCash (3%) — от 15₽
                <br />
              </p>
              <p>
                <span>PAYEER:</span>
                <br />
                (0.05%) от 1₽
              </p>
            </div>
            <div className={s.wrapper}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  disabled={(selected === "" || isLoading) ? true : false}
                  className={s.login_form_button}
                >
                  Перейти к оплате
                </Button>
              </Form.Item>
            </div>
          </>
        </Form>
      </div>
    </div>
  );
};

export default Fund;
