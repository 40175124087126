import { useDispatch, useSelector } from "react-redux";
import AdminProduct from "./AdminProduct/AdminProduct";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import s from "./AdminProducts.module.css";
import { useEffect, useState } from "react";
import AdminAddProduct from "./AdminProduct/AdminAddProduct/AdminAddProduct";
import { getProducts } from "../../../Redux/productsReduser";
import Search from "antd/es/transfer/search";
import { productsAPI } from "../../../API/productsAPI";

const AdminProducts = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const isAdmin = useSelector((state) => state.Auth.isAdmin);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAdmin) navigate("/");
  }, []);

  useEffect(() => {
    dispatch(getProducts(page - 1));
  }, [page]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const products = useSelector((state) => state.Products.products);

  const [searchedProducts, setSearchedProducts] = useState();

  const onSearch = (value) => {
    productsAPI
      .searchProduct(value)
      .then((res) => setSearchedProducts(res.data));
  };
  return (
    <div className={s.product_add}>
      <button type="primary" onClick={showModal} className={s.footer_btn}>
        Добавить Товар
        <span className={s.plus}>+</span>
      </button>
      <div style={{ maxWidth: "690px" }}>
        <Search onChange={(e) => onSearch(e.target.value)} />
      </div>
      {searchedProducts?.length > 0
        ? searchedProducts.map((prod) => (
            <AdminProduct key={prod.id} prod={prod} />
          ))
        : products.map((prod) => <AdminProduct key={prod.id} prod={prod} />)}
      <AdminAddProduct
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      />
      <div className={s.pagination}>
        <Pagination
          onChange={(page) => setPage(page)}
          current={page}
          defaultPageSize={10}
          defaultCurrent={1}
          total={300}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default AdminProducts;
