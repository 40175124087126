import { useNavigate } from "react-router-dom";
import s from "./FAQPage.module.css";

const FAQPage = () => {
    const navigate = useNavigate()
  return (
    <div className={s.container}>
      <div className={s.head}>FAQ</div>
      <div className={s.main}>Для того, чтобы купить аккаунты в магазине, нужно проделать следующие шаги:</div>
      <ul>
        <li>Ознакомиться с правилами магазина</li>
        <li>
          Выбрать нужный товар на странице магазина аккаунтов <a className={s.link} onClick={() => navigate("/")}>greedymarket.org</a>
        </li>
        <li>Прочитать описание к выбранному товару, нажав на него</li>
        <li>Нажать кнопку "Купить". Ввести нужное количество товара. </li>
        <li>
          После успешной оплаты вас вернет обратно на сайт, откроется окно с
          загрузкой аккаунтов. Имейте ввиду, что оплата может пройти не
          моментально, а спустя несколько секунд/минут. Обычно платеж происходит
          сразу.
        </li>
        <li>
          Если по какой-то причине аккаунты автоматически не выдались (сбой в
          системе оплаты, забыли указать примечание), свяжитесь с поддержкой
        </li>
      </ul>
    </div>
  );
};

export default FAQPage;
