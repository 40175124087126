import { authAPI } from "../API/authAPI";
import { refreshToken } from "../utils/refreshToken";

const initialState = {
  isAuth: false,
  isAdmin: false,
  balance: 0,
  username: null,
  email: null,
  password: null,
};

export const authReduser = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REGISTER_VALUES":
      return {
        ...state,
        username: action.username,
        email: action.email,
        password: action.password,
      };
    case "LOGIN":
        return {
          ...state,
          isAuth: true,
          isAdmin: action.is_admin,
          balance: action.balance,
          username: action.username
        };
    case "LOGOUT":
        return {
          ...state,
          isAuth: false,
        };
    default:
      return state;
  }
};


export const SetRegisterValues = (email, username, password) => {
  return {
    type: "SET_REGISTER_VALUES",
    username,
    email,
    password,
  };
};

export const LogIn = (is_admin, balance, username) => {
  return {
    type: "LOGIN",
    is_admin,
    balance,
    username
  };
};

export const Logout = () => {
  return {
    type: "LOGOUT"
  };
};

export const getMeThunk = () => async (dispatch) => {
  const response  = await refreshToken("auth/user", "get", {token: localStorage.getItem("token")})
  if(response.status === 200){
    dispatch(LogIn(response.data.is_admin, response.data.balance, response.data.username))
  }
  if(response.response.status === 401){
    dispatch(Logout)
  }
}



