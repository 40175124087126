import Header from "../components/Header/Header";
import Catalog from "../components/Catalog/Catalog";
import Footer from "../components/Footer/Footer";
import { useState } from "react";
import About from "../components/About/About";
import Error from "../components/Error/Error";

const HomePage = () => {
    const [downloadUrl, setDownloadUrl] = useState()
    const [sucPay, setSucPay] = useState(false)
    const [amount, setAmount] = useState()
    const [price, setPrice] = useState()
    const [name, setName] = useState()
    const [error, setError] = useState(false)
    const [fund, setFund] = useState(false)
    const modalsucPayChanger = (bool) => {
        setSucPay(bool)
      }
    return(
        <>
        {error && <Error setFund={setFund} setError={setError} />}
        <Header setFund={setFund} fund={fund} name={name} price={price} amount={amount} downloadUrl={downloadUrl} setSucPay={modalsucPayChanger} sucPay={sucPay}/>
        <About/>
        <Catalog setError={setError} setName={setName} setPrice={setPrice} setAmount={setAmount} setDownloadUrl={setDownloadUrl} setSucPay={modalsucPayChanger} sucPay={sucPay}/>
       
        <Footer/>
        </>
    )
}

export default HomePage