import { refreshToken } from "../utils/refreshToken"
import { instanse } from "./authAPI"

export const paymentsAPI = {
    transferBalanse(telegram_id, amount, bot){
        return refreshToken("payments/transfer", "post", {token: localStorage.getItem("token"), telegram_id, amount, bot})
        .then(res => res)
        .catch(e => e)
    },
    createInvoice(amount, service){
        return refreshToken("payments/create_invoice", "post", {token: localStorage.getItem("token"), amount, service})
        .then(res => res)
        .catch(e => e)
    },
    createTransferInvoice(telegram_id, amount, service, bot){
        return instanse.post(`payments/create_transfer_invoice?telegram_id=${telegram_id}&amount=${amount}&service=${service}&bot=${bot}`)
        .then(res => res)
        .catch(e => e)
    }
}