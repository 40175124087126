import { useNavigate } from "react-router-dom";
import s from "./ContractOffer.module.css";

const ContractOffer = () => {
    const navigate = useNavigate()
  return (
    <div className={s.container}>
      <div className={s.head}>Договор оферты</div>
      <div className={s.main}>Аккаунты предоставляются сервисом <a className={s.link} onClick={() => navigate("/")}>GreedyMarket.org</a> по принципу «как есть» и сервис не несет какой-либо ответственности за причинение или возможность причинения вреда Пользователю, его информации или бизнесу вследствие использования или невозможности использования продуктов сервиса.</div>
      <div className={s.main2}>На наших аккаунтах запрещено:</div>
      <ul>
        <li>Мошенничество, взломы, оскорбления, угрозы и клевета;</li>
        <li>
        Подбор паролей (брутфорс), сканирование и уязвимость портов;
        </li>
        <li>Создание фишинговых сайтов;</li>
        <li>Распространение вредоносных программ (вирусов, троянов и все что может влиять на работу ПО);</li>
        <li>
        Взлом сайтов и поиск их уязвимостей;
        </li>
      </ul>
    </div>
  );
};

export default ContractOffer;
