import { Form } from "antd";
import s from "../Profile.module.css";
import close from "../../../images/close.png";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../../Redux/authReduser";

const ProfileModal = (props) => {
  const { setProfile, modalBalnceTransferChanger, email, username } = props;
  const balance = useSelector((state) => state.Auth.balance)
  const dispatch = useDispatch()

    const logOut = () => {
        localStorage.removeItem('token')
        dispatch(Logout())
        setProfile(false)
    }

    const TgOpen = () => {
        setProfile(false)
        props.setTg(true)
    }

  return (
    <div className={s.wrapper}>
      <div onClick={() => setProfile(false)} className={s.modal_back}></div>
      <div className={s.window}>
        <img
          onClick={() => setProfile(false)}
          src={close}
          alt="close"
          className={s.close_img}
        />
        <div className={s.reg_title}>Твой профиль</div>
        <span className={s.add_title}>Здесь ты можешь перевести баланс </span>
        <span className={s.add_title}>или узнать данные уч. записи</span>
        <button
          onClick={modalBalnceTransferChanger}
          className={s.login_form_button}
        >
          Перенести баланс в Telegram бота
        </button>

        <Form
          name="normal_login"
          className="login-form"
          style={{width: "100%"}}
        >
          <>
            <div className={s.black_title1}>Баланс</div>
            <div className={s.balance}>{balance} ₽</div>
            {email?<><div className={s.black_title}>Электронная почта</div>

            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <div className={s.user_input}>{email}</div>
            </Form.Item></>:null}

            <div className={s.black_title}>Имя пользователя</div>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <div className={s.password_input}>
                {username?username:props.telgram_username?"@"+props.telgram_username:null}
              </div>
            </Form.Item>
            {props.telgram_username && props.username?<><div className={s.black_title}>Имя пользователя (Telegram)</div>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <div className={s.password_input}>
                {props.telgram_username}
              </div>
            </Form.Item></>:null}

              
          </>
        </Form>
        
        {props.telgram_username?null:<button onClick={TgOpen} className={s.login_form_button1}>
                Привязать Telegram
        </button>}
        <button onClick={logOut} className={s.login_form_button1}>
                Выйти из учётной записи
        </button>
      </div>
    </div>
  );
};

export default ProfileModal;
