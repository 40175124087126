import { Form } from "antd";
import close from "../../images/close.png"
import s from "./SucPay.module.css"


const SucPay = (props) => {

  const {setSucPay} = props;
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; 
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + '.' + mm + '.' + yyyy;
  const time = today.toLocaleTimeString()

  return (

    <div>
   
      <div onClick={()=>setSucPay(false)} className={s.modal_back}></div>
      <div className={s.window}>
        <img  onClick={()=>setSucPay(false)} src={close} alt="close" className={s.close_img} />
        <div className={s.reg_title1}>Оплата прошла успешно!</div>
        <div className={s.black_title1}>{props.name}</div>
     

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: false,
          }}
        >
            <> 
            <div className={s.black_title}>Ваш товар ({props.amount})</div>
            <a download={true} href={props.downloadUrl} className={s.login_form_button1}>
            Скачать товары
                </a>
           
            <div className={s.black_title1}>Итого к оплате</div>
             <div className={s.balance}>{props.price}<div style={{color:"#FD125A"}}> ₽</div></div>
                             


             <div className={s.black_title1}>Дата и время покупки</div>
             <div className={s.time}>{formattedToday}<div className={s.time_item}>{time}</div></div>
            </>
        </Form>
      </div>
    </div>
  );
};

export default SucPay;
