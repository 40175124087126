import s from "./Error.module.css";
import close from "../../images/close.png";

const Error = (props) => {
  const openFund = () => {
    props.setError(false)
    props.setFund(true)
  }

    return (
        <div>
          <div onClick={() => props.setError(false)} className={s.modal_back}></div>
          <div className={s.window}>
            <img
             onClick={() => props.setError(false)}
              src={close}
              alt="close"
              className={s.close_img}
            />
            <div className={s.reg_title}>Ошибка!</div>
           
            <div className={s.black_title}>На вашем балансе недостаточно средств</div>
            
            
                <button onClick={openFund} className={s.login_form_button}>
                Пополнить баланс
                </button>
          
          </div>
        </div>
      );
  };
 


export default Error;
