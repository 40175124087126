import BalanceTransferForm from "../BalanceTransfer/BalanceTransferForm/BalanceTransferForm";
import { useEffect, useState } from "react";
import ProfileModal from "./ProfileModal/ProfileModal";
import { authAPI } from "../../API/authAPI";

const Profile = (props) => {

  const modalBalnceTransferChanger = () => {
    props.setTransfer(true)
    props.setProfile(false)
  }

  const [email, setEmail] = useState()
  const [username, setUsername] = useState()
  const [telgram_username, setTelgram_username] = useState()

  useEffect(() => {
    authAPI.check().then(res => {
      setEmail(res.data.email)
      setUsername(res.data.username)
      setTelgram_username(res.data.telegram_username)
    })
  },[])

  return (
    <div>
      {props.transfer && <BalanceTransferForm setTransfer = {props.setTransfer} />}
      {props.profile && <ProfileModal setTg={props.setTg} telgram_username={telgram_username} username={username} email={email} modalBalnceTransferChanger={modalBalnceTransferChanger} setProfile={props.setProfile} />}
    </div>
  );
};

export default Profile;
