import s from "./PasswordRestoreSecond.module.css"
import { Input, Form, message } from "antd";
import close from "../../images/close.png"
import { authAPI } from "../../API/authAPI";


const PasswordRestoreSecond = (props) => {
    const { setPassSecond } = props;

    const [messageApi, contextHolder] = message.useMessage();
    const error = () => {
      messageApi.open({
        type: "error",
        content: "Ошибка",
      });
    };

    const onResetVerifSend = async (values) => {
        const res = await authAPI.verifyResetPassword(values.reset_code, values.new_password)
        if (res.status === 200){
            setPassSecond(false)
        }
        else error()
    }
   
    return (
        <div>
            {contextHolder}
            <div onClick={() => setPassSecond(false)} className={s.modal_back}></div>
            <div className={s.window}>

                <img onClick={() => setPassSecond(false)} src={close} alt="close" className={s.close_img} />
                <div className={s.reg_title}>Восстановление пароля</div>
                <span className={s.add_title}>Восстановите ваш аккаунт  </span>
                <span className={s.add_title}> используя свою электронную почту </span>
                <div className={s.black_title}>Введите код с почты</div>
                <Form
                    onFinish={onResetVerifSend}
                >
                <Form.Item
                    name="reset_code"
                    rules={[
                        {
                            required: true,
                            message: "Please input reset code!",
                        },
                    ]}
                >
                    <Input
                        className={s.password_input}
                        type=""
                        placeholder=""
                    />
                    </Form.Item>
                    <div className={s.black_title}>Введите новый пороль</div>
                    <Form.Item 
                    name="new_password"
                    rules={[
                        {
                            required: true,
                            message: "Please input new password!",
                        },
                    ]}
                    >
                     <Input
                        className={s.password_input}
                        placeholder="Password"
                    />
                    </Form.Item>
                    <Form.Item>
                    <button type="submit" className={s.login_form_button}>
                        Подтвердить
                    </button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default PasswordRestoreSecond;