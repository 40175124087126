import s from "./RulesPage.module.css"

const RulesPage = () => {
    return(
        <div className={s.container}>
            <div className={s.head}>Правила магазина</div> 

<div className={s.item}>1. Гарантия на товар составляет 30 минут с момента покупки, (если в описании товара ничего об этом не сказано).
В случае возникновения проблем с аккаунтами, обратитесь в поддержку магазина, мы всегда готовы разобраться в ситуации, если с момента покупки прошло более 30 минут, чем Покупатель раньше напишите, тем лучше.
</div>
<div className={s.item}>2. Возврат средств (манибек) возможен только при условии, если не имеется возможности заменить товар на аналогичный. Если доходит до манибека, и комиссия сервиса, через который будет проведен платеж, составит более 1% - комиссия будет учтена при переводе. Например, тот же киви берет 3% за перевод на иностранные кошельки.
</div>
<div className={s.item}>3. Учтите, при обращение в поддержку с целью замены, магазин в праве потребовать <b>видео покупки(на ЛЮБОЙ товар)</b>, так как не всегда можно выявить проблему именно с <b>товаром</b>, например (купоны). Запись видео - процедура необязательная, но это 100% гарантия на получение замены в случае невалида.
</div>
<div className={s.item_c1}>3.1 При записи видео покупки аккаунта нужно зафиксировать момент оплаты и выдачу товара с магазина, а затем последующею попытку авторизоваться. Копировать только через щелчок правой кнопки мышки, т.е видео, где данные копировались через <b>CTRl+C</b> и не принимаются.
</div>
<div className={s.item}>4. За необдуманную покупку, ответственность несет только <b>ПОКУПАТЕЛЬ. Возврат средств</b> или замена товара на аналогичный с какими-то дополнительными критериями <b>НЕВОЗМОЖНА!</b> Все возникшие вопросы лучше уточнять до покупки у <b>ПОДДЕРЖКИ</b> магазина.
</div>
<div className={s.item}>5. Гарантия на использование товара, распространяется на то, что в аккаунт можно войти по логину и паролю, если в полученном товаре после покупки, содержатся данные <b>п.5.1</b>  их использования <b>ОБЯЗАТЕЛЬНО!</b>
    </div>
    <div className={s.item_c1}>5.1 User Agent/cookies/Fingerprint/Прокси/Токен/API/Формат определенной программы и т.п.
        </div>
        <div className={s.item_c2}>5.1.1 если аккаунты <b>НЕ</b> подходят под Ваш софт/расширение браузера и.т.д - это не является причиной для возврата/замены товара.
</div>
<div className={s.item}>6. Покупая товар, Покупатель обдуманно осознаете тот факт, что умеете с ним работать, магазин не является поставщиком этих товаров и сотрудники магазина, не занимаются обучением по использованию товара, поэтому мы  <b>НЕ</b> сможем научить/полноценно проконсультировать по использованию товара покупателя.
</div>
<div className={s.item}>7. Если в аккаунт можно войти через компьютер, и он является рабочим, а на другом устройстве/браузере/софте и т.д. вход ограничен или невозможен по определенным причинам, то это <b>НЕ</b> является причиной для возврата/замены товара, за исключением, когда товар предназначен исключительно для определенного устройства.
</div>
<div className={s.item}>8. Покупая любой товар, используйте/проверяйте <b>СРАЗУ</b>, не откладывая его, иначе потом Вы лишаетесь гарантии на то, что сможете получить возврат/замену товара.
</div>
<div className={s.item}>9. После получения товара, <b>ПОКУПАТЕЛЬ</b> обязуется сохранить товар у <b>СЕБЯ</b>, с возможностью дальнейшего использования, магазин <b>НЕ</b> является хранилищем купленного товара.
    </div>
<div className={s.item_c1}>9.1 В случае, если магазин выдал замену товара, покупатель <b>ОБЯЗУЕТСЯ</b> сохранить данный аккаунт у <b>СЕБЯ</b>, магазин <b>НЕ</b> является хранилищем по купленному товару.
</div>
<div className={s.item}>10. Покупатель обязуется не создавать лишний флуд/спам/информацию, в сторону магазина, в противном случае магазин вправе <b>ОТКАЗАТЬ</b> в обслуживании на момент обращения и в дальнейшем, <b>БЕЗ</b> какой либо компенсации.
</div>
<div className={s.item}>11. <b>Запрещено</b> оставлять сообщение <b>БЕЗ</b> данных о заказе сразу после покупки, а затем прислать номер заказа/список невалида по истечении гарантийного срока.
</div>
<div className={s.item}>12. Покупатель <b>НЕ</b> сможет получить замену/возврат за аккаунты, которые уже использовал, а после обратился с целью получить замену/возврата товара.
</div>
<div className={s.item}>13. Покупателю <b>запрещено</b> использовать аккаунты, за которые был сделан возврат или выдана замена.
</div>
<div className={s.item}>14. Независимо оттого, в сети поддержка магазина или нет, обращение по заказу должно быть составлено сразу при обнаружении проблемы, в ином случае Вы лишаетесь гарантии на заказ.
</div>
<div className={s.item}>15. <b>Использование ПРОКСИ</b> обязательно при работе с товаром, в ином случае магазин имеет право отказать в рассмотрение заказа с дальнейшем отказом в замене/возврате товара
    </div>
    <div className={s.item}>16. Платежи проведенные в <b>ручном режиме</b>, не могут быть возвращены на реквизиты отличающиеся при оплате, решение о таком платеже принимает <b>Администрации сервиса</b>, вплоть до отказа зачислении денежных средств.
</div>
<div className={s.footer}>Магазин вправе изменять правила без предварительного уведомления покупателей!<br/>
Незнание правил не освобождает от ответственности.</div>
        </div>
    )
}

export default RulesPage