import { Button, Form, Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { addProductThunk } from "../../../../../Redux/productsReduser";


const AdminAddProduct = (props) => {

    const dispatch = useDispatch()

    const categories = useSelector((state) => state.Products.categories)

    const onFinish = (values) => {
        dispatch(addProductThunk(values.title, values.description, +values.price, categories.filter(cat => values.categories.includes(cat.id)), values.link))
        props.onCancel()
        form.resetFields()
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

      const options = []
      categories.map((cat) => options.push({
        label: cat.title,
        value: cat.id,
      }))

      const [form] = Form.useForm();

  return (
    <>
      <Modal
        title="Basic Modal"
        open={props.open}
        onCancel={props.onCancel}
        footer={[]}
      >
        <Form
        form={form}
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Title"
      name="title"
      rules={[
        {
          message: 'Please input Title!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Description"
      name="description"
      rules={[
        {
          message: 'Please input Description!',
        },
      ]}
    >
      <TextArea />
    </Form.Item>
    <Form.Item
      label="Description link"
      name="link"
      rules={[
        {
          message: 'Please input Description link!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Price"
      name="price"
      rules={[
        {
          message: 'Please input Price!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Categories"
      name="categories"
    >
      <Select
      mode="multiple"
      allowClear
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      defaultValue={[]}
      onChange={(values) => console.log(values)}
      options={options}
    />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
      </Modal>
    </>
  );
};

export default AdminAddProduct
