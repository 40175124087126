import { useDispatch, useSelector } from "react-redux";
import love from "../../images/love.png";
import heart from "../../images/heart.png";
import CatalogItem from "./CatalogItem/CatalogItem";
import { productsAPI } from "../../API/productsAPI";
import { useEffect, useState, useMemo } from "react";
import {
  SetActiveCategory,
  SetPage,
  getCategoriesImgsThunk,
  setActiveCatThunk,
} from "../../Redux/productsReduser";
import s from "./Catalog.module.css";
import { Pagination, Popover } from "antd";
import CategorySection from "./CategorySection/CategorySection";
import loop from "../../images/loop.png"
import loopSearch from "../../images/loopSearch.png"
import devilmobile from "../../images/devilmobile.png"
import little_heart from "../../images/little heart.png"
import arrow from "../../images/arrow.png"


const Catalog = (props) => {
  let i = 0;
  const [ctg, setCtg] = useState(false)
 

 const modalCtgChanger = (bool) => {
  setCtg (bool)
 
 }

  const dispatch = useDispatch();
  const onActiveCategory = (id, title, color) => {
    dispatch(setActiveCatThunk(id, title, color));
  };

  const page = useSelector((state) => state.Products.page);
  const categories = useSelector((state) => state.Products.categories);
  const selectedCategory = useSelector(
    (state) => state.Products.activeCategory
  );
  const selectedImg = useSelector((state) => state.Products.selectedImg)

  const categoriesImgs = useSelector((state) => state.Products.catalogImgs);

  const categoriesContent = (
    <div>
      {
        categories.map((cat) => (
          <p
            key={cat.id}
            className={s.select_item}
            style={{ backgroundColor: cat.color, cursor: "pointer" }}
            onClick={() => onActiveCategory(cat.id, cat.title, cat.color)}
          >
            {cat.title}
          </p>
        ))}
    </div>
  );

  useEffect(() => {
    if (categories.length > 0) {
      dispatch(
        getCategoriesImgsThunk(
          [
            categories[page * 3 - 3]?.id,
            categories[page * 3 - 2]?.id,
            categories[page * 3 - 1]?.id,
          ],
          page
        )
      );
    }
  }, [page, categories]);

  const [searchedProducts, setSearchedProducts] = useState();
  const onSearch = (value) => {
    productsAPI
      .searchProduct(value)
      .then((res) => setSearchedProducts(res.data));
  };

  return (
    <>
      <div className={s.conn}>
        <div className={s.catalog}>
          <div className={s.operations}>
            <div className={s.categories}>
              <div onClick={ctg ? () => modalCtgChanger(false) : () => modalCtgChanger(true)} className={s.category_item}>
                <Popover placement="bottom" content={categoriesContent} open={ctg} >
                  <div style={{ cursor: "pointer" }}>Категории</div>
                </Popover>
                <img className={ctg ?  s.arrow_active : s.arrow } src={arrow} alt="arrow" />
              </div>

              <div className={s.main_find}>
                <input
                  onChange={(e) => onSearch(e.target.value)}
                  className={s.find}
                  type="text"
                  placeholder="Поиск товара"
                />
                <div className={s.loop}>
                  <img src={loop} alt="loop" />
                </div>
                <button className={s.reset} onClick={() => dispatch(SetActiveCategory(null, null, null))}>Сбросить</button>
              </div>
            </div>



          </div>


          <div className={s.top_catalog}>
            <div className={s.top_catalog_item1}>Название </div>
            <div className={s.top_catalog_item2}>В наличии</div>
            <div className={s.top_catalog_item3}>Цена</div>
          </div>

          {searchedProducts?.length > 0 ? (
            searchedProducts.map((prod) => <CatalogItem img={loopSearch} setError={props.setError} key={prod.id} setName={props.setName} setPrice={props.setPrice} setAmount={props.setAmount} setDownloadUrl={props.setDownloadUrl} setSucPay={props.setSucPay} product={prod} />)
          ) : selectedCategory&&selectedImg ? (
            <CategorySection
              setError={props.setError}
              setName={props.setName}
              setPrice={props.setPrice}
              setAmount={props.setAmount}
              setDownloadUrl={props.setDownloadUrl}
              setSucPay={props.setSucPay}
              searchedProducts={searchedProducts}
              category={selectedCategory}
              img={selectedImg}
            />
          ) : (
            categories
              .slice(page * 3 - 3, page * 3)
              .map((cat) => (
                <CategorySection
                  setError={props.setError}
                  setName={props.setName}
                  setPrice={props.setPrice}
                  setAmount={props.setAmount}
                  setDownloadUrl={props.setDownloadUrl}
                  setSucPay={props.setSucPay}
                  searchedProducts={searchedProducts}
                  category={cat}
                  img={categoriesImgs[i++]}
                  key={cat.id}
                />
              ))
          )}
          <div className={s.page_num}>
            <Pagination
              onChange={(page) => dispatch(SetPage(page))}
              current={page}
              defaultPageSize={3}
              defaultCurrent={1}
              total={categories.length}
            />
          </div>

          <div className={s.love}>
            <div className={s.love_desc}>
              <div className={s.aboba}>
                <div className={s.love_item1}>Gree-ндим твою любовь к нам</div>
                <img className={s.heart1} src={little_heart} alt="heart" />
                <img className={s.heart} src={heart} alt="heart" />

              </div>
              <div className={s.love_item2}>Промокод на скидку 5%</div>
              <img className={s.devilmobile} src={devilmobile} alt="" />
              <button className={s.love_item3}>#GREEDY</button>
              <div className={s.pro}>Промокод действителен до 31.05.2023</div>
            </div>

            <img src={love} className={s.love_img} alt="love" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Catalog;
