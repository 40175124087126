import { adminAPI } from "../API/adminAPI";
import { getMeThunk } from "./authReduser";

const initialState = {
  users: [],
};

export const usersReduser = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_USER_BALANCE":
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.id
            ? {
                ...user,
                balance: action.balance
              }
            : user
        ),
      };
    case "CHANGE_USER_ROLE":
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.id
            ? {
                ...user,
                role: action.role
              }
            : user
        ),
      };
    case "SET_USERS":
      return {
        ...state,
        users: [...action.users]
      };
    default:
      return state;
  }
};

export const ChangeUserBalance = (id, balance) => {
  return {
    type: "CHANGE_USER_BALANCE",
    id,
    balance
  };
};
export const ChangeUserRole = (id, role) => {
  return {
    type: "CHANGE_USER_ROLE",
    id,
    role
  };
};

export const SetUsers = (users) => {
  return {
    type: "SET_USERS",
    users
  };
};

export const changeUserBalanceThunk = (id, balance) => async (dispatch) => {
  const res = await adminAPI.changeUserBalance(id, balance)
  if(res.status === 200) {
    const response = await adminAPI.getUsers(0)
    dispatch(SetUsers(response.data))
    dispatch(getMeThunk())
  }
  else(alert("error"))
}

export const changeUserAdminStatusThunk = (id, admin_status) => async (dispatch) => {
  const res = await adminAPI.changeUserAdminStatus(id, admin_status)
  if(res.status === 200){
    const response = await adminAPI.getUsers(0)
    dispatch(SetUsers(response.data))
  }
  else(alert("error"))
}

export const getUsersThunk = (pageNumber) => async (dispatch) => {
  const response = await adminAPI.getUsers(pageNumber)
  dispatch(SetUsers(response.data))
}
